import { memo, useEffect, useState } from "react";
import { Box, Stack, TableCell, TableRow } from "@mui/material";
import { IOngoingOrders, IOrderStatusEnum, TOrderStatus, OrderPaymentType } from "../../../../../api/types/orders";
import dateHelper from "../../../../../utils/date-helper";
import OnTheWayTimer from "../OnTheWayTimer";
import AcceptedRiderButton from "../AcceptedRiderButton";
import CancelOrderButton from "../CancelOrderButton";
import CustomTooltip from "../../../../../components/app/CustomTooltip";
import { CiWallet, CiDollar } from "react-icons/ci";
import "./styles.css";
import { USER_ROLE } from "../../../../../types/user";
import { useGlobal } from "../../../../../context/GlobalContext";

interface IOngoingOrderItemProps {
    setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
    setOpenCancelOrderConfirmationModal: (isOpen: boolean) => void;
    orderItem: IOngoingOrders;
    setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OngoingOrderItem: React.FC<IOngoingOrderItemProps> = ({ setIsOpenAcceptedRiderModal, setOpenCancelOrderConfirmationModal, orderItem, setSelectedOrder }) => {
    const { user } = useGlobal();
    const [flag, setFlag] = useState<string>("");
    let timeoutId: any;

    const handleFlagColor = () => {
        setFlag(orderItem?.flag || "");
        timeoutId = setTimeout(() => {
            delete orderItem.flag;
            setFlag("");
        }, 1000);
    }

    useEffect(() => {
        handleFlagColor();
        return () => {
            clearTimeout(timeoutId);
        }
    }, []);

    const getStateFromOrderHistory = (colStatusType: TOrderStatus, completeFormat: boolean = false): {
        toastForm: string;
        valueForm: string
    } => {
        let item = orderItem.histories.filter(orderHistory => orderHistory.status === colStatusType);

        if (item.length > 0) {
            return completeFormat ? {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm")
            } : {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "HH:mm")
            }
        } else {
            return {
                toastForm: "",
                valueForm: "-"
            }
        }
    }

    const showOnTheWayBriefly = (duration: string) => {
        return duration.replace(/hours|hour/, "h").replace(/minutes|minute/, "m").replace(/seconds|second/, "s");
    }

    const renderStatusCells = (colStatusType: TOrderStatus) => {
        let cell: JSX.Element = <TableCell> - </TableCell>;
        let cellClass = "";

        if (colStatusType === IOrderStatusEnum.Pending) {
            if (IOrderStatusEnum.Pending === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <CustomTooltip title={getStateFromOrderHistory(colStatusType, true).toastForm}>
                        <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType, true).valueForm}</TableCell>
                    </CustomTooltip>
                    <TableCell> - </TableCell>
                </>;
            } else {
                cellClass = "";
                cell = <CustomTooltip title={getStateFromOrderHistory(colStatusType, true).toastForm}>
                    <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType, true).valueForm}</TableCell>
                </CustomTooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.RiderAccepted) {
            if (IOrderStatusEnum.RiderAccepted === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                        <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                    </CustomTooltip>
                    <TableCell> - </TableCell>
                </>;
            } else {
                cellClass = "";
                cell = <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                    <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                </CustomTooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.PickedUp) {
            if (IOrderStatusEnum.PickedUp === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                        <TableCell>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                    </CustomTooltip>
                    <TableCell className={cellClass}>
                        <OnTheWayTimer
                            orderItem={orderItem}
                            orderStatus={orderItem.status}
                            orderOnTheWayDuration={orderItem.onTheWayDuration} />
                    </TableCell>
                </>;
            } else {
                cellClass = "";
                cell = <>
                    <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                        <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                    </CustomTooltip>
                </>;
            }
        } else if (colStatusType === IOrderStatusEnum.Delivered) {
            if (IOrderStatusEnum.Delivered === orderItem.status) {
                cellClass = "";
                cell = <>
                    <CustomTooltip title={dateHelper.getDuration(orderItem.onTheWayDuration)}>
                        <TableCell>{showOnTheWayBriefly(dateHelper.getDuration(orderItem.onTheWayDuration))}</TableCell>
                    </CustomTooltip>
                    <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                        <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                    </CustomTooltip>
                </>;
            } else {
                cellClass = "";
                cell = <CustomTooltip title={getStateFromOrderHistory(colStatusType).toastForm}>
                    <TableCell className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</TableCell>
                </CustomTooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.ZoneCanceled) {
            if (IOrderStatusEnum.ZoneCanceled === orderItem.status) {
                cell = <>
                    <TableCell> - </TableCell>
                    <TableCell> - </TableCell>
                </>;
            } else {
                cell = <TableCell> - </TableCell>;
            }
        }

        return cell;
    }

    const renderActionButttons = () => {
        let disableAcceptedRiderBtn: boolean = false;
        let disableCancelOrderBtn: boolean = false;

        if ([IOrderStatusEnum.Pending, IOrderStatusEnum.ZoneCanceled].includes(orderItem.status)) {
            disableAcceptedRiderBtn = true;
        }

        if (!orderItem.availableActions.includes(IOrderStatusEnum.ZoneCanceled)) {
            disableCancelOrderBtn = true;
        }

        return <Stack direction="row" alignItems="center">
            <AcceptedRiderButton
                isDisabled={disableAcceptedRiderBtn}
                handleOnClick={() => {
                    setIsOpenAcceptedRiderModal(true);
                    setSelectedOrder(orderItem);
                }}
            />
            {
                user?.role === USER_ROLE.ZoneAgent &&
                <CancelOrderButton
                    isDisabled={disableCancelOrderBtn}
                    handleOnClick={() => {
                        setOpenCancelOrderConfirmationModal(true);
                        setSelectedOrder(orderItem);
                    }}
                />
            }
        </Stack>;
    }

    const handleOrderItemClassName = () => {
        return orderItem.status === IOrderStatusEnum.ZoneCanceled ? "order_item_row canceled" : `${flag} order_item_row`;
    }

    const handleOrderValue = () => {
        const icon = orderItem.paymentType === OrderPaymentType.Paid
            ? <CiWallet size={21} />
            : <CiDollar size={23} />;

        return <CustomTooltip title={orderItem.paymentType}>
            <Box display="flex" alignItems="center">{icon}&nbsp;{orderItem.cost}</Box>
        </CustomTooltip>
    }

    return <TableRow key={orderItem.id} className={handleOrderItemClassName()}>
        <TableCell sx={{ maxWidth: "130px", wordWrap: "break-word" }}>#{orderItem.code}</TableCell>
        <TableCell>{orderItem.hasOtp ? orderItem.otpCode : "-"}</TableCell>
        <TableCell>{handleOrderValue()}</TableCell>
        {user?.role === USER_ROLE.GroupAgent && <TableCell>{orderItem.applicant}</TableCell>}
        {renderStatusCells(IOrderStatusEnum.Pending)}
        {renderStatusCells(IOrderStatusEnum.RiderAccepted)}
        {renderStatusCells(IOrderStatusEnum.PickedUp)}
        {orderItem.status === IOrderStatusEnum.Delivered ? renderStatusCells(IOrderStatusEnum.Delivered) : renderStatusCells(IOrderStatusEnum.ZoneCanceled)}
        <TableCell>{orderItem.vehicleType}</TableCell>
        <TableCell>{renderActionButttons()}</TableCell>
    </TableRow>;
}

export default memo(OngoingOrderItem);