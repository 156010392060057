import { AxiosInstance } from ".."
import { API_ROUTES } from "../../constants/api-routes";
import { INewAddress } from "../types/address";
import { IAxiosResponse } from "../types/axios-response";
import { ISearchCustomerPayload } from "../types/customers";

export const customerService = {
    searchCustomer(groupId: string, phoneNumber: string, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<ISearchCustomerPayload>>(`${API_ROUTES.CUSTOMERS.SEARCH_CUSTOMER(groupId)}?phoneNumber=${phoneNumber}`, { signal: abortSignal });
    },
    addAddressToNewCustomer(phoneNumber: string, groupId: string, address: INewAddress, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<string>>(`${API_ROUTES.CUSTOMERS.ADD_NEW_CUSTOMER_WITH_ADDRESS}`, {
            phoneNumber,
            groupId,
            address
        }, { signal: abortSignal })
    },
    addAddressForAlreadyCustomer(customerId: string, groupId: string, address: INewAddress, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<string>>(`${API_ROUTES.CUSTOMERS.ADD_ADDRESS_FOR_ALREADY_CUSTOMER(customerId)}`, {
            groupId,
            ...address
        }, { signal: abortSignal })
    }
}