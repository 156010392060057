import { Button } from "@mui/material";
import React, { useState } from "react";
import ConfirmationDialogue from "../ConfirmationDialogue";


const NewRequest: React.FC = () => {
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

    return <>
        <Button
            className="new_request_btn"
            variant="contained"
            color="info"
            onClick={() => setOpenConfirmationModal(true)}>Create New Order</Button>

        <ConfirmationDialogue isOpen={openConfirmationModal} setOpenModal={setOpenConfirmationModal} />
    </>
}

export default NewRequest;