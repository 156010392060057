import { AxiosInstance } from ".."
import { API_ROUTES } from "../../constants/api-routes";
import { ILoginResponse, IZoneUserProfile } from "../types/auth";
import { IAxiosResponse } from "../types/axios-response";

export const authService = {
    login(username: string, password: string, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<ILoginResponse>>(API_ROUTES.AUTH.LOGIN, { username, password }, { signal: abortSignal });
    },
    profile(abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IZoneUserProfile>>(API_ROUTES.AUTH.PROFILE, { signal: abortSignal });
    }
}