import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CustomModal from "../../../../../components/app/CustomModal";
import { IOngoingOrders } from "../../../../../api/types/orders";
import { useTranslation } from "react-i18next";


interface IAcceptedRiderModalProps {
    isOpen: boolean;
    handleClose: () => void;
    orderItem: IOngoingOrders;
}

const AcceptedRiderModal: React.FC<IAcceptedRiderModalProps> = ({ isOpen, handleClose, orderItem }) => {
    const { t } = useTranslation();

    const renderAcceptedRiders = () => {
        return orderItem.acceptedRiders?.map((item, ind) => {
            return <TableRow key={ind * new Date().getTime()}>
                <TableCell>{item.employeeId}</TableCell>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.contactNo || "---"}</TableCell>
            </TableRow>
        });
    }

    return <CustomModal isOpen={isOpen} onClose={handleClose}>
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p="10px" borderBottom="1px solid #E6E6E6" mb="12px">
                <Typography variant="body1">Accepted Rider</Typography>
                <Typography variant="body1">#{orderItem.code}</Typography>
            </Stack>
            <TableContainer>
                <Table>
                    <TableHead sx={{ backgroundColor: "#E3E3E3" }}>
                        <TableRow>
                            <TableCell>{t("fields.employeeId")}</TableCell>
                            <TableCell>{t("fields.firstName")}</TableCell>
                            <TableCell>{t("fields.lastName")}</TableCell>
                            <TableCell>{t("fields.contactNo")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderAcceptedRiders()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    </CustomModal>;
}

export default AcceptedRiderModal;