import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

interface ICustomTooltipProps {
    title: string;
    children: JSX.Element;
    styles?: React.CSSProperties;
}

const CustomTooltip: React.FC<ICustomTooltipProps> = ({ title, children, styles }) => {
    return <Tooltip title={title} arrow componentsProps={{
        tooltip: {
            sx: {
                marginTop: "-9px !important",
                ...styles
            }
        }
    }}>
        {children}
    </Tooltip>
};

export default CustomTooltip;