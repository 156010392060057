import { Box, CircularProgress, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IAddressItem, IAddressItemWithCustomer } from "../../../../../api/types/address";
import { customerService } from "../../../../../api/services/customer";
import { useOrderRegisterContext } from "../OrderRegisterProvider";
import { useGlobal } from "../../../../../context/GlobalContext";

interface IAddressListProps {
    phoneNumber: string;
    handleMoveToAddressForm: () => void;
    selectedAddress: IAddressItem;
    handleSelectAddress: (selectedAddress: IAddressItemWithCustomer) => void;
}

const AddressList: React.FC<IAddressListProps> = ({ phoneNumber, handleMoveToAddressForm, selectedAddress, handleSelectAddress }) => {
    const { setcustomerData } = useOrderRegisterContext();
    const { user } = useGlobal();

    const [loadingAddress, setLoadingAdress] = useState<boolean>(false);
    const [addresses, setAddresses] = useState<IAddressItem[]>([]);
    const [customerId, setCustomerId] = useState<string>("");
    const [phoneSearchAbortController] = useState<AbortController>(new AbortController());

    useEffect(() => {
        let searchPhoneNumber: ReturnType<typeof setTimeout>;
        const phoneVal = phoneNumber;

        if (phoneVal.length >= 6) {
            setLoadingAdress(true);
            const groupId = user?.groupId || "";
            searchPhoneNumber = setTimeout(async () => {
                try {
                    const response = await customerService.searchCustomer(groupId, phoneVal, phoneSearchAbortController.signal);
                    const result = response.data.data;
                    if (!result) {
                        // if result be null -> POST
                        setcustomerData({
                            id: null,
                            phoneNumber
                        });
                        setAddresses([]);
                    } else {
                        // result isn't null and must be parse -> PUT
                        setcustomerData({
                            id: result.id,
                            phoneNumber
                        });
                        setAddresses(result.addresses);
                        setCustomerId(result.id);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoadingAdress(false);
                }
            }, 500);
        }

        return () => {
            clearTimeout(searchPhoneNumber);
            setLoadingAdress(false);
            handleSelectAddress({} as IAddressItemWithCustomer);
        };
    }, [phoneNumber]);

    const renderAddresses = () => {
        let options: ReactNode;

        if (addresses.length > 0) {
            options = addresses.map((addressItem, ind) => {
                return <Box key={ind} pb="2px" mb="2px" sx={ind !== (addresses.length - 1) ? { borderBottom: "1px solid #e6e6e6" } : {}}>
                    <FormControlLabel value={addressItem.id}
                        control={<Radio size="small"
                            sx={{
                                '&.Mui-checked': {
                                    color: "#FE9920",
                                },
                                marginLeft: "2px"
                            }} />
                        }
                        label={<Box>
                            <Typography fontSize="12px" fontWeight="300">{addressItem.address}</Typography>
                        </Box>}
                        onChange={() => handleSelectAddress({
                            ...addressItem,
                            customerId
                        })} />
                </Box>;
            })

            return <FormControl fullWidth>
                <RadioGroup value={selectedAddress?.id || ""}>
                    {options}
                </RadioGroup>
            </FormControl>
        }

        return <Typography variant="caption" color="#aaa">No address found for this customer</Typography>;
    }

    return <Box p={1} bgcolor="#F5F5F5" mt={1} borderRadius={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">Select Your Address</Typography>
            {!loadingAddress && <IconButton onClick={handleMoveToAddressForm}>
                <FaPlus size={15} />
            </IconButton>}
        </Stack>
        <Box mt={1}>
            {loadingAddress && <CircularProgress size={20} />}
            {
                !loadingAddress && <Box maxHeight="150px" overflow="auto" fontSize={10}>
                    {renderAddresses()}
                </Box>
            }
        </Box>
    </Box>;
}

export default AddressList;