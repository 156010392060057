export const API_ROUTES = {
    BASE_URL: ``,
    AUTH: {
        LOGIN: `/zone-api/authenticate`,
        PROFILE: `/zone-api/users/_self`
    },
    ORDERS: {
        CREATE_NEW_ORDER: `zone-api/orders/check-out`,
        GET_ONGOING_ORDERS: `/paging-api/zones/orders`,
        CANCEL_ORDER: (id: string) => `/paging-api/zones/orders/cancel/${id}`,
        GET_ORDER_HISTORIES: `/paging-api/zones/orders/histories`,
        GET_AREAS_CITIES: `/paging-api/area-cities`
    },
    CUSTOMERS: {
        SEARCH_CUSTOMER: (groupId: string) => `/paging-api/customers/${groupId}`,
        ADD_NEW_CUSTOMER_WITH_ADDRESS: `/paging-api/customers`,
        ADD_ADDRESS_FOR_ALREADY_CUSTOMER: (id: string) => `/paging-api/customers/${id}`
    }
}