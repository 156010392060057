import { useState } from "react";
import { Box } from "@mui/material";
import ROUTE_CONSTANTS from "../../routes/route-constant";
import { useNavigate } from "react-router-dom";
import { SCREEN_BREAKPOINTS } from "../../constants/screen-breakpoints";
import { matchMediaHelper } from "../../utils/screen-helper";
import { ACCESS_TOKEN } from "../../constants/access-token";
import { useGlobal } from "../../context/GlobalContext";
import { Navbar } from "./components/Navbar";
import { Drawer } from "./components/Drawer";
import { Content } from "./components/Content";
import "./styles.css";

const DashboardLayout: React.FC = () => {
    const isSmallScreen = matchMediaHelper.checkMaxWidthMedia(SCREEN_BREAKPOINTS.MD.MAX);
    const [openSidebar, setOpenSidebar] = useState<boolean>(isSmallScreen ? false : true);
    const { setUser } = useGlobal();
    const navigate = useNavigate();

    const handleSigout = () => {
        setUser(undefined);
        localStorage.removeItem(ACCESS_TOKEN);
        navigate(ROUTE_CONSTANTS.ROOT.RELATIVE);
    }

    return <Box className="dashboard-layout">
        <Navbar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} isSmallScreen={isSmallScreen} handleSigout={handleSigout} />
        <Drawer openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} isSmallScreen={isSmallScreen} handleSigout={handleSigout} />
        <Content openSidebar={openSidebar} />
    </Box >;
}

export default DashboardLayout;