export const matchMediaHelper = {
    checkMaxWidthMedia(max: string) {
        return window.matchMedia(`(max-width:${max})`).matches;
    },
    checkMinWidthMedia(min: string) {
        return window.matchMedia(`(min-width:${min})`).matches;
    },
    checkRangeWidthMedia(min: string, max: string) {
        return window.matchMedia(`(min-width:${min}) and (max-width: ${max})`).matches;
    }
}