import { Box } from "@mui/material";
import React from "react";
import "./styles.css";



const HorizontalLoading: React.FC = () => {
    return <Box className="horizontal-animate-item"></Box>
}

export default HorizontalLoading;