const ROUTE_CONSTANTS = {
    ROOT: {
        RELATIVE: "/",
        ABSOLUTE: "/"
    },
    ROOT_STAR: {
        RELATIVE: "/*",
        ABSOLUTE: "/*"
    },
    AUTH: {
        ROOT: {
            RELATIVE: "auth",
            ABSOLUTE: "/auth"
        },
        LOGIN: {
            RELATIVE: "login",
            ABSOLUTE: "/auth/login"
        }
    },
    DASHBOARD: {
        ROOT: {
            RELATIVE: "dashboard",
            ABSOLUTE: "/dashboard"
        },
        HOME: {
            RELATIVE: "home",
            ABSOLUTE: "/dashboard/home"
        },
        WELCOME: {
            RELATIVE: "welcome",
            ABSOLUTE: "/dashboard/welcome"
        },
        OrderList: {
            RELATIVE: "order-list",
            ABSOLUTE: "/dashboard/order-list"
        },
        OrderHistory: {
            RELATIVE: "order-history",
            ABSOLUTE: "/dashboard/order-history"
        },
    }
};

export default ROUTE_CONSTANTS;