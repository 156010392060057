import { RiderType } from "./rider-type";
import { TVehicleTypes } from "./vehicle";

export enum OrderWebSocketEvents {
    OrderStarted = "OrderStarted",
    RiderAccepted = "RiderAccepted",
    OrderStatusChanged = "OrderStatusChanged"
}

interface IAcceptedRiders {
    id: string;
    orderId: string;
    firstName: string;
    lastName: string;
    avatar: string;
    employeeId: string;
    civilIdNo: string;
    driverLicenseNo: string;
    contactNo: string;
    riderType: RiderType;
}

interface IEligibleRiders {
    id: string;
    distance: number;
    employeeId: string;
    firstName: string;
    lastName: string;
    location: {
        latitude: number;
        longitude: number;
    },
    status: string;
}

export enum IOrderStatusEnum {
    Pending = "Pending",
    RiderAccepted = "RiderAccepted",
    RiderArrived = "RiderArrived",
    RiderCanceled = "RiderCanceled",
    ZoneCanceled = "ZoneCanceled",
    SystemCanceled = "SystemCanceled",
    PickedUp = "PickedUp",
    Delivered = "Delivered",
    Finished = "Finished"
}

export type TOrderStatus =
    IOrderStatusEnum.Pending |
    IOrderStatusEnum.RiderAccepted |
    IOrderStatusEnum.RiderArrived |
    IOrderStatusEnum.RiderCanceled |
    IOrderStatusEnum.ZoneCanceled |
    IOrderStatusEnum.SystemCanceled |
    IOrderStatusEnum.PickedUp |
    IOrderStatusEnum.Delivered |
    IOrderStatusEnum.Finished;

interface IOrderAddress {
    originLocation: {
        latitude: number;
        longitude: number;
    },
    destinationLocation: {
        latitude: number;
        longitude: number;
    },
    originLocationName: string;
    destinationLocationName: string;
}

interface IOrdreZoneData {
    id: string;
    groupId: string;
    nameAr: string;
    descriptionAr: string;
    nameEn: string;
    descriptionEn: string;
    addressAr: string;
    addressEn: string;
    location: {
        latitude: number;
        longitude: number;
    },
    validRadius: number;
    pagingRadius: number;
}

export interface IOrderHistories {
    id: string,
    date: string,
    status: TOrderStatus,
    location: {
        latitude: number;
        longitude: number;
    },
    duration: number,
    comment: string,
    statusBy: string
}

export interface IOngoingOrders {
    acceptedRiders?: IAcceptedRiders[];
    availableActions: TOrderStatus[];
    address: IOrderAddress;
    applicant: string;
    date: string;
    description: string;
    histories: IOrderHistories[];
    id: string;
    reference: string;
    status: TOrderStatus;
    code: string;
    cost: number;
    vehicleType: TVehicleTypes;
    onTheWayDuration: number;
    paymentType: TOrderPaymentType;
    customerPhoneNumber: string;
    customerAddress: string;
    otpCode: string;
    hasOtp: boolean;
    flag?: OrderFlagType.ADDED | OrderFlagType.UPDATED;
}

export interface IOngoingOrdersItems {
    items: IOngoingOrders[];
}

export interface IGenerateOrderResponse {
    zoneAgentId: string;
    adminId: string;
    requestId: string;
    zone: IOrdreZoneData;
    eligibleRiders: IEligibleRiders[],
    id: string;
    creationDate: string;
}

export enum OrderFlagType {
    ADDED = "added",
    UPDATED = "updated",
}

export interface IRiderAccepted extends Pick<IOngoingOrders, "id" | "acceptedRiders" | "histories" | "status"> {
    availableActions: TOrderStatus
}

export interface IOrderChanged extends Pick<IOngoingOrders, "id" | "histories" | "onTheWayDuration" | "status"> {
    availableActions: TOrderStatus;
}

export interface IOrderRequest {
    code: string;
    cost: number;
    vehicleType: TVehicleTypes;
    phoneNumber: string;
    paymentType: TOrderPaymentType;
    deliveryAddressId: string;
    customerId: string;
}

export interface INewOrderData extends Omit<IOrderRequest, "phoneNumber"> { }

export enum OrderModalTypeEnum {
    AcceptedRiders = "AcceptedRiders",
    CancelOrder = "CancelOrder",
}

export type TOrderModalType = OrderModalTypeEnum.CancelOrder | OrderModalTypeEnum.AcceptedRiders;

export interface ICancelOrderPayload {
    availableActions: TOrderStatus[] | [];
}

export interface IOrderHistoriesPayload {
    totalCount: number;
    items: IOngoingOrders[];
    nextPageUrl: string;
}

export enum OrderPaymentType {
    Paid = "Paid",
    Cash = "Cash"
};

export type TOrderPaymentType = OrderPaymentType.Paid | OrderPaymentType.Cash;

export interface ISubAreaCities {
    id: number;
    parentId: number;
    nameEn: string;
    nameAr: string;
    slug: string;
}

export interface IAreaCityItem extends ISubAreaCities {
    subAreaCities: ISubAreaCities[];
}

export interface IAreaCityListPayload {
    totalCount: number;
    items: IAreaCityItem[];
    nextPageUrl: string;
}

export interface IAreaCityOpt extends Pick<ISubAreaCities, "id" | "nameEn"> { };