import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useOrderRegisterContext } from "../OrderRegisterProvider";
import { FaCheckCircle, FaChevronLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { INewAddress } from "../../../../../api/types/address";
import { useState, useTransition } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressRequestValidationForm } from "../../../../../validation-schemas/addressRequestForm";
import { ISubAreaCities } from "../../../../../api/types/orders";
import { customerService } from "../../../../../api/services/customer";
import { useGlobal } from "../../../../../context/GlobalContext";
import { LoadingButton } from "@mui/lab";

interface ICreateNewAddressProps {
    areas: ISubAreaCities[];
    cities: ISubAreaCities[];
}

const CreateNewAddress: React.FC<ICreateNewAddressProps> = ({ areas, cities }) => {
    const { customerData } = useOrderRegisterContext();
    const { user } = useGlobal();
    const groupId = user?.groupId || "";

    const [subCities, setSubCities] = useState<ISubAreaCities[]>(cities.filter(cityItem => cityItem.parentId === areas[0].id));
    const [areaValue, setAreaValue] = useState<number>(areas[0].id);
    const [cityValue, setCityValue] = useState<number>(cities.filter(cityItem => cityItem.parentId === areas[0].id)[0].id);
    const [addAddressAbortController] = useState<AbortController>(new AbortController());
    const [showSuccessState, setShowSuccessState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const t = useTransition();

    const { setCurrentViewForm } = useOrderRegisterContext();
    const { register, handleSubmit, reset, formState: { isValid } } = useForm<INewAddress>({
        resolver: yupResolver(addressRequestValidationForm(t)),
        mode: "all",
        defaultValues: {
            areaId: areas[0].id,
            cityId: cities.filter(cityItem => cityItem.parentId === areas[0].id)[0].id,
            street: "",
            block: "",
            building: "",
            avenue: "",
            floor: "",
            apartment: "",
            extraInfo: ""
        }
    });

    const addAddressToNewCustomer = async (addressData: INewAddress) => {
        const result = await customerService.addAddressToNewCustomer(customerData.phoneNumber, groupId, {
            ...addressData,
            areaId: areaValue,
            cityId: cityValue
        }, addAddressAbortController.signal);
        // console.log(`registered address id: ${result.data.data}`);
    }

    const addAddressForAlreadyCustomer = async (addressData: INewAddress) => {
        const result = await customerService.addAddressForAlreadyCustomer(customerData.id || "", groupId, {
            ...addressData,
            areaId: areaValue,
            cityId: cityValue
        }, addAddressAbortController.signal);
        // console.log(`registered address id: ${result.data.data}`);
    }

    const onAddressSubmit = async (addressData: INewAddress) => {
        // Call API to register new address and come back to order form
        try {
            setLoading(true);
            if (!customerData.id) {
                // console.log("--- register NEW customer ---", addressData);
                // It is new customer
                await addAddressToNewCustomer(addressData);
            } else {
                // It is already customer
                // console.log("=== register ALREADY customer ===", addressData);
                await addAddressForAlreadyCustomer(addressData);
            }

            setShowSuccessState(true);
            setTimeout(() => {
                setCurrentViewForm("OrderForm");
            }, 1500);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleOnChangeArea = (areaId: number) => {
        // console.log(`-- selected area: ${areaId}`);
        const subCitiesOfAnArea = cities.filter(cityItem => cityItem.parentId === +areaId);
        setAreaValue(areaId);
        setCityValue(subCitiesOfAnArea[0].id);
        setSubCities(subCitiesOfAnArea);
    }

    const handleOnChangeCity = (cityId: number) => {
        // console.log(`city: ${cityId}`);
        setCityValue(cityId);
    }

    return <Box>
        {!showSuccessState ? <Box fontSize="1.2rem">
            {/* <p>{!customerData.id ? "POST" : "PUT"}</p>
            <p style={{ color: "#f00" }}>ID: <span style={{ color: "#888" }}>{customerData.id}</span></p>
            <p style={{ color: "#f00" }}>GroupID: <span style={{ color: "#888" }}>{groupId}</span></p>
            <br /> */}
            <Typography fontSize="1em" mb={1}>Add new address for {customerData.phoneNumber}</Typography>
            <form noValidate autoComplete="off" method="post" onSubmit={handleSubmit(onAddressSubmit)}>
                <Box bgcolor="#F5F5F5" borderRadius={2} p={3}>
                    <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel required>Area</InputLabel>
                            <Select required label="Area" size="small" value={areaValue} autoFocus
                                {...register("areaId", { required: true })}
                                onChange={(e: any) => handleOnChangeArea(e.target.value)}
                                sx={{ backgroundColor: "#fff" }}>
                                {
                                    areas.map(areaItem => {
                                        return <MenuItem key={areaItem.id} value={areaItem.id}>{areaItem.nameEn}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel required>City</InputLabel>
                            <Select required label="City" size="small" value={cityValue}
                                {...register("cityId", { required: true })}
                                onChange={(e: any) => handleOnChangeCity(e.target.value)}
                                sx={{ backgroundColor: "#fff" }}>
                                {
                                    subCities.map(cityItem => {
                                        return <MenuItem key={cityItem.id} value={cityItem.id}>{cityItem.nameEn}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} mt={2}>
                        <TextField required fullWidth label="Block" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("block", { required: true })}
                            sx={{ backgroundColor: "#fff" }} />
                        <TextField required fullWidth label="Street" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("street", { required: true })}
                            sx={{ backgroundColor: "#fff" }} />
                    </Stack>
                    <Stack direction="row" spacing={2} mt={2}>
                        <TextField fullWidth label="Avenus" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("avenue")}
                            sx={{ backgroundColor: "#fff" }} />
                        <TextField required fullWidth label="Building" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("building", { required: true })}
                            sx={{ backgroundColor: "#fff" }} />
                    </Stack>
                    <Stack direction="row" spacing={2} mt={2} mb={2}>
                        <TextField fullWidth label="Floor" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("floor")}
                            sx={{ backgroundColor: "#fff" }} />
                        <TextField fullWidth label="Apart" type="text" variant="outlined"
                            size="small" autoComplete="off"
                            {...register("apartment")}
                            sx={{ backgroundColor: "#fff" }} />
                    </Stack>
                    <TextField fullWidth label="Extra Info" type="text" variant="outlined"
                        size="small" autoComplete="off"
                        {...register("extraInfo")}
                        sx={{ backgroundColor: "#fff" }} />
                </Box>
                <Stack direction="row" spacing={2} mt={2} className="actions">
                    <Button
                        variant="outlined"
                        type="button"
                        size="large"
                        color="secondary"
                        onClick={() => setCurrentViewForm("OrderForm")}
                        startIcon={<FaChevronLeft size={15} />}
                        disabled={loading}>Back</Button>
                    <LoadingButton type="submit" variant="contained" color="info" size="large" loading={loading} disabled={!isValid}>Save</LoadingButton>
                </Stack>
            </form>
        </Box>
            : <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight="320px">
                <FaCheckCircle color="#34C759" size={90} />
                <Typography variant="h5" mt={6} textAlign="center">Your address saved successfully.</Typography>
            </Box>
        }
    </Box>;
}

export default CreateNewAddress;