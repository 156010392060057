export enum OrdersDataStoreActionKind {
    SET_ORDERS = "SET_ORDERS",
    ADD_NEW_ORDER = "ADD_NEW_ORDER",
    ACCEPT_ORDER = "ACCEPT_ORDER",
    UPDATE_ORDER = "UPDATE_ORDER",
    REMOVE_ORDER = "REMOVE_ORDER",
    SET_SELECTED_ORDER = "SET_SELECTED_ORDER",
    CLOSE_MODAL = "CLOSE_MODAL",
    CANCEL_ORDER = "CANCEL_ORDER"
}

export interface OrdersDataStoreAction<T> {
    type: OrdersDataStoreActionKind;
    data?: T
}