import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HorizontalLoading from "../../../../../components/app/HorizontalLoading";
import PageContentWrapper from "../../../../../components/app/PageContentWrapepr";
import { NotFoundResult } from "../../../../../components/app/NotFoundResult";
import AcceptedRiderModal from "../AcceptedRiderModal";
import { IOngoingOrders } from "../../../../../api/types/orders";
import { ordersService } from "../../../../../api/services/orders";
import dateHelper from "../../../../../utils/date-helper";
import OrderHistoryItem from "../OrderHistoryItem";
import { Pagination } from "../../../../../components/app/Pagination";
import { useSearch } from "../../../../../hooks/useSearch";
import { useTitle } from "../../../../../hooks/useTitle";
import { useGlobal } from "../../../../../context/GlobalContext";
import { USER_ROLE } from "../../../../../types/user";

const OrderHistorytList: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitle.pagingOrdersHistory"));

    const { user } = useGlobal();

    const [getAbortController] = useState<AbortController>(new AbortController());
    const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpenAcceptedRiderModal, setIsOpenAcceptedRiderModal] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<IOngoingOrders>({} as IOngoingOrders);
    const [orders, setOrders] = useState<IOngoingOrders[]>([]);
    const [pageTotalCount, setPageTotalCount] = useState<number>(0);
    const { searchQuery, updateSearchParameters } = useSearch({ isStoreOnLocalStorage: false, entity: "Order", fields: { query: "", size: 10, offset: 0 } });

    const fetchOrderHistories = async (offset: number, size: number) => {
        try {
            setLoading(true);
            const toDate = dateHelper.formatDate(new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toString(), "YYYY-MM-DDTHH:mm:ssZ");
            const result = await ordersService.getOrderHistories({ offset: offset, count: size, toDate }, getAbortController.signal);
            setPageTotalCount(result.data.data.totalCount);
            setOrders(result.data.data.items);
            result.data.data.items.length > 0 ? setShowNoResultFound(false) : setShowNoResultFound(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderHistories(searchQuery.offset, searchQuery.size);
    }, []);

    const handlePassPaginateValues = (offset: number, size: number) => {
        updateSearchParameters({ offset, size });
        fetchOrderHistories(offset, size);
    }

    return <>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h5">
                {t("titles.pagingHistory")}
            </Typography>
        </Box>
        <Box height="5px" mb="1">
            {loading && <HorizontalLoading />}
        </Box>
        <PageContentWrapper>
            <TableContainer>
                {!showNoResultFound ?
                    <Table sx={{ backgroundColor: "#fff", border: "1px solid #E6E6E6" }}>
                        <TableHead sx={{ backgroundColor: "#E3E3E3" }}>
                            <TableRow sx={{ borderRadius: "8px 8px 0 0 " }}>
                                <TableCell>{t("fields.orderID")}</TableCell>
                                <TableCell>{t("fields.orderValue")} (KD)</TableCell>
                                {user?.role === USER_ROLE.GroupAgent && <TableCell>{t("fields.zone")}</TableCell>}
                                <TableCell>{t("fields.pending")}</TableCell>
                                <TableCell>{t("fields.accepted")}</TableCell>
                                <TableCell>{t("fields.pickedUp")}</TableCell>
                                <TableCell>{t("fields.delivered")}</TableCell>
                                <TableCell>{t("fields.vehicleType")}</TableCell>
                                <TableCell>{t("fields.action")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orders.map(orderItem => {
                                    return <OrderHistoryItem
                                        key={orderItem.id}
                                        orderItem={orderItem}
                                        setIsOpenAcceptedRiderModal={setIsOpenAcceptedRiderModal}
                                        setSelectedOrder={setSelectedOrder} />
                                })
                            }
                        </TableBody>
                    </Table> : <NotFoundResult iconMaxWidth={250} />
                }
            </TableContainer>
        </PageContentWrapper>

        <Pagination
            entityTitle="Order"
            pageTotalCount={pageTotalCount}
            perPageItems={searchQuery.size}
            passPaginateValues={handlePassPaginateValues} />

        {/* Accepted Modal */}
        <AcceptedRiderModal
            isOpen={isOpenAcceptedRiderModal}
            handleClose={() => setIsOpenAcceptedRiderModal(false)}
            orderItem={selectedOrder} />
    </>
}

export default OrderHistorytList;