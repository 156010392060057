import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import CustomModal from "../../../../../components/app/CustomModal";
import FormHelper from "../../../../../utils/form-helper";
import { ISubAreaCities, IOrderRequest, OrderPaymentType, TOrderPaymentType } from "../../../../../api/types/orders";
import { VehicleTypes } from "../../../../../api/types/vehicle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { orderRequestValidationSchema } from "../../../../../validation-schemas/orderRequestForm";
import AddressList from "../AddressList";
import CreateNewAddress from "../CreateNewAddress";
import { useOrderRegisterContext } from "../OrderRegisterProvider";
import { IAddressItemWithCustomer } from "../../../../../api/types/address";
import { ordersService } from "../../../../../api/services/orders";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "../../../../../constants/api-default-values";
import SnackbarUtils from '../../../../../utils/set-snackbar';
import CountDownTimerButton from "../CountDownTimerButton";
import { RiErrorWarningLine } from "react-icons/ri";
import "./styles.css";

interface IConfirmationDialogueProps {
    isOpen: boolean;
    setOpenModal: (isOpen: boolean) => void;
}

const ConfirmationDialogue: React.FC<IConfirmationDialogueProps> = ({ isOpen, setOpenModal }) => {
    const { t } = useTranslation();

    const { currentViewForm, setCurrentViewForm } = useOrderRegisterContext();
    const [deliveryAddress, setDeliveryAddress] = useState<IAddressItemWithCustomer>({} as IAddressItemWithCustomer);
    const [getAllAreasCitiesAbortController] = useState(new AbortController());
    const [areas, setAreas] = useState<ISubAreaCities[]>([]);
    const [cities, setCities] = useState<ISubAreaCities[]>([]);
    const [paymentVal, setPaymentVal] = useState<TOrderPaymentType>(OrderPaymentType.Paid);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [newOrderAbortController] = useState<AbortController>(new AbortController());
    const [loading, setloading] = useState<boolean>(false);

    const formHelperInstance = new FormHelper();
    const { register, handleSubmit, reset, watch, formState: { isValid, errors } } = useForm<IOrderRequest>({
        resolver: yupResolver(orderRequestValidationSchema(t)),
        mode: "all",
        defaultValues: {
            code: "",
            cost: 0,
            vehicleType: VehicleTypes.Bike,
            paymentType: OrderPaymentType.Paid,
            phoneNumber: ""
        }
    });

    const closeConfirmationDialogue = () => {
        setAlertMessage("");
        setDeliveryAddress({} as IAddressItemWithCustomer);
        setPaymentVal(OrderPaymentType.Paid);
        reset();
        setOpenModal(false);
    }

    const onOrderSubmit = async (data: IOrderRequest) => {
        setloading(true);
        try {
            await ordersService.createNewOrder({
                code: data.code,
                cost: +data.cost,
                vehicleType: data.vehicleType,
                paymentType: data.paymentType,
                deliveryAddressId: deliveryAddress.id,
                customerId: deliveryAddress.customerId
            }, newOrderAbortController.signal);

            SnackbarUtils.success(t("messages.generateSuccessfullRequest"));
            setAlertMessage("");
            setPaymentVal(OrderPaymentType.Paid);
            reset();
            setOpenModal(false);
        } catch (error: any) {
            console.log(error);
        } finally {
            setloading(false);
        }
    }

    const getAllAreasCities = async (moveToAddressForm: boolean = false) => {
        if (areas.length > 0) {
            return true;
        }

        try {
            const result = await ordersService.getAllAreaCities({ offset: DEFAULT_OFFSET, count: DEFAULT_COUNT }, getAllAreasCitiesAbortController.signal);
            const items = result.data.data.items;
            const areaItems = items.filter(item => !item.parentId);
            const cityList: ISubAreaCities[] = [];
            items.forEach(item => {
                item.subAreaCities.forEach(subItem => {
                    cityList.push(subItem);
                });
            });

            setAreas(areaItems);
            setCities(cityList);
            if (moveToAddressForm) {
                setAlertMessage("");
                setCurrentViewForm("AddressForm");
            }
        } catch (error) {
            console.log("Error occured on fetch areas/cities: ", error);
        }
    }

    useEffect(() => {
        isOpen && getAllAreasCities();
    }, [isOpen]);

    const isValidPhoneNumber = () => {
        return !errors.phoneNumber?.message && watch("phoneNumber").trim() !== "";
    }

    const handleDisablityOfSubmitBtn = () => {
        return !isValid ? true : (isValid && Object.keys(deliveryAddress).length > 0) ? false : true;
    }

    return <CustomModal isOpen={isOpen}>
        <Box className="confirmation_dialogue_container">
            {
                currentViewForm === "OrderForm"
                    ? <>
                        <Box fontSize="1.2rem">
                            <Typography textAlign="center" fontSize="1em">Are you sure that you want to send new request?</Typography>
                        </Box>
                        <form noValidate autoComplete="off" method="post" onSubmit={handleSubmit(onOrderSubmit)} className="order_form">
                            <Box mt={2}>
                                <TextField variant="outlined" size="small" fullWidth label="Order ID" {...register("code", { required: true, minLength: 3 })} autoComplete="off" autoFocus />
                                <Stack direction="row" spacing={2} mt={2}>
                                    <TextField fullWidth label="Value" type="text" variant="outlined"
                                        size="small" {...register("cost", { required: true })}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => formHelperInstance.enterJustNumber(e)}
                                        autoComplete="off"
                                        InputProps={{
                                            endAdornment: <InputAdornment disableTypography position="end">Kd</InputAdornment>
                                        }} />
                                    <FormControl fullWidth>
                                        <InputLabel>Vehicle Type</InputLabel>
                                        <Select label="Vehicle Type" size="small" defaultValue={VehicleTypes.Bike} {...register("vehicleType", { required: true })}>
                                            <MenuItem value={VehicleTypes.Bike}>{VehicleTypes.Bike}</MenuItem>
                                            {/* <MenuItem value={VehicleTypes.Car}>{VehicleTypes.Car}</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} mt={2}>
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" size="small" fullWidth label="Phone Number"
                                            {...register("phoneNumber", {
                                                required: true,
                                                minLength: 6,
                                                pattern: {
                                                    value: /^[+]?[0-9]{6,13}$/,
                                                    message: "Please enter a valid phone number"
                                                },
                                                onChange: (e: any) => formHelperInstance.preventSpace(e)
                                            })}
                                            autoComplete="off" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Payment Type</InputLabel>
                                        <Select label="Payment Type" size="small" value={paymentVal}
                                            {...register("paymentType", {
                                                required: true,
                                                onChange: (e: any) => setPaymentVal(e.target.value)
                                            })}>
                                            <MenuItem value={OrderPaymentType.Paid}>{OrderPaymentType.Paid}</MenuItem>
                                            <MenuItem value={OrderPaymentType.Cash}>{OrderPaymentType.Cash}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {
                                    errors.phoneNumber?.message &&
                                    <Typography variant="caption" color="red"><small>{errors.phoneNumber?.message}</small></Typography>
                                }
                                {
                                    isValidPhoneNumber() &&
                                    <AddressList
                                        phoneNumber={watch("phoneNumber")}
                                        handleMoveToAddressForm={() => {
                                            if (areas.length > 0) {
                                                setAlertMessage("");
                                                setCurrentViewForm("AddressForm");
                                            } else {
                                                getAllAreasCities(true);
                                            }
                                        }}
                                        selectedAddress={deliveryAddress}
                                        handleSelectAddress={(selectedAddress: IAddressItemWithCustomer) => setDeliveryAddress(selectedAddress)} />
                                }
                                {
                                    alertMessage !== "" &&
                                    <Box mt={1}
                                        bgcolor="#FFF6E8"
                                        fontSize="13px"
                                        color="#D88805"
                                        borderRadius={2}
                                        display="flex"
                                        alignItems="center"
                                        padding="12px"><RiErrorWarningLine size={20} />&nbsp;{alertMessage}</Box>
                                }
                                <Stack direction="row" spacing={2} mt={1} className="actions">
                                    <LoadingButton variant="outlined" type="button" size="large" color="secondary" onClick={closeConfirmationDialogue} disabled={loading}>No</LoadingButton>
                                    {
                                        alertMessage !== ""
                                            ? <CountDownTimerButton handleTimerFinished={() => setAlertMessage("")} />
                                            : <LoadingButton variant="contained"
                                                type="submit"
                                                size="large"
                                                color="info"
                                                disabled={handleDisablityOfSubmitBtn()}
                                                loading={loading}>Yes</LoadingButton>
                                    }
                                </Stack>
                            </Box>
                        </form>
                    </>
                    : <CreateNewAddress areas={areas} cities={cities} />
            }
        </Box>
    </CustomModal >
}

export default ConfirmationDialogue;