import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import "./styles.css";

const GeneralLayout: React.FC = () => {
    return <Box className="general-layout">
        <Outlet />
    </Box>
}

export default GeneralLayout;