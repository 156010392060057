import { contextFactory } from "../../../../../utils/context-factory";
import { useState } from "react";

export interface IOrderRegisterContext {
    currentViewForm: "OrderForm" | "AddressForm",
    setCurrentViewForm: React.Dispatch<React.SetStateAction<"OrderForm" | "AddressForm">>;
    customerData: {
        id: string | null;
        phoneNumber: string;
    };
    setcustomerData: React.Dispatch<React.SetStateAction<{
        id: string | null;
        phoneNumber: string;
    }>>;
}

export const [OrderRegisterContext, useOrderRegisterContext] = contextFactory<IOrderRegisterContext>("orderRegisterContext", "setOrderRegisterContext");

interface IOrderRegisterContextProps {
    children: JSX.Element;
}

const OrderRegisterProvider: React.FC<IOrderRegisterContextProps> = ({ children }) => {
    const [currentViewForm, setCurrentViewForm] = useState<"OrderForm" | "AddressForm">("OrderForm");
    const [customerData, setcustomerData] = useState<{
        id: string | null;
        phoneNumber: string;
    }>({
        id: null,
        phoneNumber: ""
    });

    return <OrderRegisterContext.Provider value={{
        currentViewForm,
        setCurrentViewForm,
        customerData,
        setcustomerData
    }}> {children}
    </OrderRegisterContext.Provider>
}

export default OrderRegisterProvider;