import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ACCESS_TOKEN } from "../constants/access-token";
import { HTTP_STATUS_CODES } from "../constants/http-status-codes";
import { getAuthorization } from "../utils/get-authorization";
import SnackbarUtils from '../utils/set-snackbar';
import { v4 as uuidV4 } from 'uuid';

export const requestInterceptors = {
    beforeSent(config: AxiosRequestConfig): AxiosRequestConfig {
        config.headers = { ...config.headers };

        if (config?.url?.includes('zone-api/') || config?.url?.includes('paging-api/')) {
            const token = getAuthorization();

            if (token) {
                config.headers.Authorization = token;
            }
        }

        if (config?.url?.includes('orders/check-out') || config?.url?.includes('zones/orders/cancel')) {
            const uuid = uuidV4();
            config.headers["x-requestid"] = uuid;
        }

        return config;
    },
    onError(error: AxiosError): Promise<AxiosError> {
        return Promise.reject(error);
    }
}

export const responseInterceptors = {
    successInterceptor(response: AxiosResponse): AxiosResponse {
        return response;
    },
    errorInterceptor(error: any): any {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        const statusCode = error?.response?.status;
        let url = error?.response?.config?.url;

        let checkOutOrder = /^zone-api\/orders\/check-out$/; // register new order
        if (checkOutOrder.test(url) && statusCode === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) { // on 422 status code
            return Promise.reject(error);
        }

        if (statusCode) {
            switch (statusCode) {
                case HTTP_STATUS_CODES.CACHED_RESPONSE:
                    return Promise.resolve(error.response.data);
                case HTTP_STATUS_CODES.VALIDATION_ERROR:
                    SnackbarUtils.error(error.response?.data?.meta?.message);
                    break;
                case HTTP_STATUS_CODES.SERVER_ERROR:
                    SnackbarUtils.error(error.response?.data?.meta?.message);
                    break;
                case HTTP_STATUS_CODES.NOT_FOUND:
                    SnackbarUtils.error(error.response?.data?.meta?.message);
                    break;
                case HTTP_STATUS_CODES.UN_AUTHORIZED:
                    SnackbarUtils.error(error.response?.data?.meta?.message);
                    localStorage.removeItem(ACCESS_TOKEN);
                    window.location.reload();
                    break;
                case HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY:
                    SnackbarUtils.error(error.response?.data?.meta?.message);
                    break;
                default:
                    return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
}
