import { IconButton, Tooltip } from "@mui/material";
import { BiUserCheck } from "react-icons/bi";

interface IAcceptedRiderButtonProps {
    isDisabled: boolean;
    handleOnClick: () => void;
}

const AcceptedRiderButton:React.FC<IAcceptedRiderButtonProps> = ({isDisabled, handleOnClick}) => {
    return <>
        <Tooltip title="Accepted Rider">
            <span>
                <IconButton aria-label="accepted rider" size="small" 
                        disabled={isDisabled}
                        onClick={handleOnClick}>
                    <BiUserCheck/>
                </IconButton>
            </span>
        </Tooltip>
    </>;
}

export default AcceptedRiderButton;