import { ThemeProvider } from '@mui/material';
import { createTheme, Shadows } from "@mui/material/styles";
import Routes from './routes';
import { SnackbarUtilsConfigurator } from './utils/set-snackbar';
import './App.css';

function App() {
  const theme = createTheme({
    shadows: Array(25).fill("none") as Shadows,
    typography: {
      fontFamily: "FilsonPro"
    },
    components: {
      MuiModal: {
        styleOverrides: {
          root: {
            outline: "none"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "6px 12px"
          }
        }
      }
    },
    palette: {
      secondary: {
        main: "#B3B3B3",
        contrastText: "#808080"
      },
      info: {
        main: "#FEE4BA",
        contrastText: "#000"
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarUtilsConfigurator />
        <Routes />
      </ThemeProvider>
    </>
  );
}

export default App;
