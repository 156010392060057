import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SCREEN_BREAKPOINTS } from "../../../constants/screen-breakpoints";
import { matchMediaHelper } from "../../../utils/screen-helper";
import "./styles.css";

interface ICustomModalProps {
    isOpen: boolean;
    styles?: React.CSSProperties;
    children: JSX.Element;
    closeOnBackdropOrESCKeydown?: boolean;
    onClose?: () => void;
}

const CustomModal: React.FC<ICustomModalProps> = ({ isOpen, styles, children, closeOnBackdropOrESCKeydown = false, onClose = () => {} }) => {
    const isSmallScreen = matchMediaHelper.checkMaxWidthMedia(SCREEN_BREAKPOINTS.MD.MAX);
    const [openModal, setOpenModal] = useState<boolean>(isOpen);

    const handleCloseModal = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if(closeOnBackdropOrESCKeydown) {
            (reason === "backdropClick" || reason === "escapeKeyDown") ? setOpenModal(false) : setOpenModal(true);
        } else {
            (reason === "backdropClick" || reason === "escapeKeyDown") ? setOpenModal(true) : setOpenModal(false);
        }
        onClose();
        return;
    }

    useEffect(() => {
        setOpenModal(isOpen);
    }, [isOpen]);

    return <Modal open={openModal} onClose={handleCloseModal}>
        <Box className="mask-container" sx={isSmallScreen ? { ...styles, maxWidth: "92%" } : { maxWidth: "720px", ...styles }}>
            {children}
        </Box>
    </Modal>
}

export default CustomModal;