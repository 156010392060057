import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

interface IContentProps {
    openSidebar: boolean;
}

export const Content: React.FC<IContentProps> = ({ openSidebar }) => {
    return <Box className={openSidebar ? "main-content" : "main-content extended"}>
        <Outlet />
    </Box>
}