import { Box, Typography, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from "../../../api/services/auth";
import { IloginForm } from "../../../api/types/login";
import Logo from "../../../components/app/Logo";
import { loginValidationSchema } from "../../../validation-schemas/login";
import { useTranslation } from "react-i18next";
import { setAuthorization } from "../../../utils/set-authorization";
import ROUTE_CONSTANTS from "../../../routes/route-constant";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import { useTitle } from "../../../hooks/useTitle";
import { useGlobal } from "../../../context/GlobalContext";
import "./styles.css";

const Login: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitle.login"));
    const { setUser } = useGlobal();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [loginAbortController] = useState<AbortController>(new AbortController());
    const { register, handleSubmit, formState: { errors } } = useForm<IloginForm>({
        resolver: yupResolver(loginValidationSchema(t)),
        mode: "all",
        defaultValues: {
            username: "",
            password: ""
        }
    });

    const onLoginSubmit = async (data: IloginForm) => {
        setLoading(true);
        try {
            const result = await authService.login(data.username, data.password, loginAbortController.signal);
            setAuthorization(result.data.data.token);
            const {token, ...userData} = result.data.data;
            setUser(userData);
            navigate(ROUTE_CONSTANTS.DASHBOARD.OrderList.ABSOLUTE);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return <>
        <Logo width={150} height={150} />
        <form className="login-container" noValidate autoComplete="off" method="POST" onSubmit={handleSubmit(onLoginSubmit)}>
            <Typography variant="h5" fontWeight="500">Sign In</Typography>
            <Typography variant="subtitle1" color="#808080" mt={1}>Welcome to Beep Beep Zone Panel</Typography>
            <Box mt={4} display="flex" flexDirection="column" width="100%">
                <Box sx={{ mb: "8px" }}>
                    <TextField label="Username" variant="filled" fullWidth
                        {...register("username", { required: true })} />
                    <Typography variant="body2" color="red">{errors.username?.message}</Typography>
                </Box>
                <Box>
                    <TextField label="Password" variant="filled" type="password" fullWidth
                        {...register("password", { required: true, minLength: 6 })} />
                    <Typography variant="body2" color="red">{errors.password?.message}</Typography>
                </Box>
                <LoadingButton type="submit" variant="contained" color="error" size="large" loading={loading} sx={{ mt: "40px" }}>Sign In</LoadingButton>
            </Box>
        </form>
    </>
}

export default Login;