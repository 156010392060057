import React from "react";
import { SnackbarProvider } from "notistack";

interface ICustomSnackbarProviderProps {
    children: JSX.Element;
}

const CustomSnackbarProvider: React.FC<ICustomSnackbarProviderProps> = ({ children }) => {
    return <SnackbarProvider
        autoHideDuration={3000}
        hideIconVariant={true}
        preventDuplicate={true}
        maxSnack={4}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}>
            {children}
    </SnackbarProvider>;
}

export default CustomSnackbarProvider;