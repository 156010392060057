import { Box } from "@mui/material"
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai"
import { FaMapMarkerAlt, FaTh, FaUserCircle } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import { RxExit } from "react-icons/rx";
import { useGlobal } from "../../../../context/GlobalContext";
import { useOutsideClickAlerter } from "../../../../hooks/useOutsideClickAlerter";
import { USER_ROLE } from "../../../../types/user";

interface INavbarProps {
    openSidebar: boolean;
    setOpenSidebar: (isOpen: boolean) => void;
    isSmallScreen: boolean;
    handleSigout: () => void;
}

export const Navbar: React.FC<INavbarProps> = ({ openSidebar, setOpenSidebar, isSmallScreen, handleSigout }) => {
    const { user } = useGlobal();
    const [openProfileDropdown, setOpenProfileDropdown] = useState<boolean>(false);
    const profileDropdownRef = useRef<any>();
    const clickOutsideProfileDropdown = useOutsideClickAlerter(profileDropdownRef);

    useEffect(() => {
        clickOutsideProfileDropdown && openProfileDropdown ? setOpenProfileDropdown(true) : setOpenProfileDropdown(false);
    }, [clickOutsideProfileDropdown, openProfileDropdown]);

    const renderEntityBox = () => {
        if (user?.role === USER_ROLE.GroupAgent) {
            return <>
                <FaTh size={18} style={{ verticalAlign: "middle" }} />&nbsp; {user?.groupName || "---"}
            </>
        } else {
            return <>
                <FaMapMarkerAlt size={18} style={{ verticalAlign: "middle" }} />&nbsp; {user?.zoneName || "---"} 
            </>
        }
    }

    return <header className="navbar">
        <a onClick={() => setOpenSidebar(!openSidebar)}><FiAlignJustify size={26} /></a>
        <Box className="avatar-wrapper">
            {
                !isSmallScreen && <Box className="desk-avatar">
                    <Box display="flex" gap={2}>
                        <Box padding="8px 12px" borderRadius={4} border="1px solid #F5F5F5">{renderEntityBox()}</Box>
                        <Box padding="8px 12px" borderRadius={4} border="1px solid #F5F5F5" bgcolor="#F5F5F5">
                            <FaUserCircle size={18} style={{ verticalAlign: "middle" }} />&nbsp; {`${user?.firstName} ${user?.lastName}`}
                        </Box>
                    </Box>
                </Box>
            }
            {
                isSmallScreen && <Box position="relative" ref={profileDropdownRef}>
                    <span className="mob-avatar" onClick={() => setOpenProfileDropdown(!openProfileDropdown)}>
                        <AiOutlineUser size={24} />
                    </span>
                    {openProfileDropdown && <Box className="mob-avatar-dropdown">
                        <Box pb={1} mb={1} borderBottom="1px solid #E6E6E6">
                            <FaUserCircle size={18} style={{ verticalAlign: "middle" }} />&nbsp;{`${user?.firstName} ${user?.lastName}`}
                        </Box>
                        <Box>{renderEntityBox()}</Box>
                        <Box className="logout">
                            <a onClick={handleSigout} style={{ display: "block" }}>
                                <RxExit size={22} style={{ transform: "rotateY(180deg)", verticalAlign: "middle" }} /> Logout
                            </a>
                        </Box>
                    </Box>}
                </Box>
            }
        </Box>
    </header >
}