import { Box, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "../../api/services/auth";
import { IZoneUserProfile } from "../../api/types/auth";
import { HTTP_STATUS_CODES } from "../../constants/http-status-codes";
import ROUTE_CONSTANTS from "../../routes/route-constant";
import { contextFactory } from "../../utils/context-factory";
import { getAuthorization } from "../../utils/get-authorization";
import { IGlobalContext } from "./types";
interface IGlobalContextProps {
    children: JSX.Element;
}

export const [GlobalContext, useGlobal] = contextFactory<IGlobalContext>("GlobalContext", "useGlobal");

export const GlobalProvider: React.FC<IGlobalContextProps> = ({ children }) => {
    const [user, setUser] = useState<IZoneUserProfile | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const { pathname } = useLocation();
    const [getProfileController] = useState(new AbortController());
    const navigate = useNavigate();

    const getUserProfile = useCallback(async () => {
        try {
            if (getAuthorization()) {
                const result = await authService.profile(getProfileController.signal);
                setUser(result.data.data);
            } else if (!user) {
                if (pathname.includes("dashboard")) {
                    navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
                }
            }
        } catch (err) {
            console.log(err);
            const error = err as AxiosResponse;
            if (error.status && error.status === HTTP_STATUS_CODES.UN_AUTHORIZED && pathname.includes("dashboard")) {
                navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
            }
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        getUserProfile();
    }, [getUserProfile])

    useEffect(() => {
        return () => {
            getProfileController.abort();
        }
    }, [getProfileController]);

    return (
        <GlobalContext.Provider value={{ user, setUser }}>
            {loading ? <Box sx={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CircularProgress />
            </Box> : children}
        </GlobalContext.Provider>
    );
}