import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { RxTimer } from "react-icons/rx";
import { IOngoingOrders, IOrderStatusEnum, TOrderStatus } from "../../../../../api/types/orders";
import dateHelper from "../../../../../utils/date-helper";

interface IOnTheWayTimerProps {
    orderItem: IOngoingOrders;
    orderStatus: TOrderStatus;
    orderOnTheWayDuration: number;
}

const OnTheWayTimer: React.FC<IOnTheWayTimerProps> = ({ orderItem, orderStatus, orderOnTheWayDuration }) => {
    let onTheWayTimerInterval: any;
    const [onTheWayTimer, setOnTheWayTimer] = useState<number>(orderOnTheWayDuration);

    const handleOnTheWayTimer = () => {
        onTheWayTimerInterval = setInterval(() => {
            setOnTheWayTimer(preVal => preVal + 60);
        }, 60000);
    }

    useEffect(() => {
        orderStatus === IOrderStatusEnum.PickedUp && handleOnTheWayTimer();
    }, [orderStatus]);

    useEffect(() => {
        return () => {
            clearInterval(onTheWayTimerInterval);
        }
    }, []);

    return <Stack direction="row" display="flex" alignItems="center">
        <RxTimer size={18} />&nbsp;{dateHelper.getDuration(onTheWayTimer, false).substring(0, 5)}
    </Stack>
}

export default OnTheWayTimer;