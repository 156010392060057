import { AnySchema, object, string } from "yup";
import { INewAddress } from "../api/types/address";

export const addressRequestValidationForm = (t: any) => object().shape<Record<keyof INewAddress, AnySchema>>({
    areaId: string().trim().required(),
    cityId: string().trim().required(),
    street: string().trim().min(1).required(),
    block: string().trim().min(1).required(),
    building: string().trim().min(1).required(),
    avenue: string().nullable(true).optional(),
    floor: string().nullable(true).optional(),
    apartment: string().nullable(true).optional(),
    extraInfo: string().nullable(true).optional()
});