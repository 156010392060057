import React, { useEffect, useState } from "react";

export function useOutsideClickAlerter(ref: React.RefObject<HTMLButtonElement>) {
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClicked(false);
      } else {
        setClicked(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return clicked;
}