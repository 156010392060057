import React from "react"
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../../hooks/useTitle";
import OngoingOrderList from "../components/OnGoingOrderList";
import OrderDataProvider from "../OrdersDataContext";

const OrderList: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitle.pagingOrders"));

    return <Box>
        <OrderDataProvider>
            <OngoingOrderList />
        </OrderDataProvider>
    </Box>;
}

export default OrderList;