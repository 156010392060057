import { AxiosInstance } from ".."
import { API_ROUTES } from "../../constants/api-routes";
import { IAreaCityListPayload, ICancelOrderPayload, IGenerateOrderResponse, INewOrderData, IOngoingOrdersItems, IOrderHistoriesPayload } from "../types/orders";
import { IAxiosResponse } from "../types/axios-response";

export const ordersService = {
    createNewOrder({ code, cost, vehicleType, paymentType, customerId, deliveryAddressId }: INewOrderData, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<IGenerateOrderResponse>>(API_ROUTES.ORDERS.CREATE_NEW_ORDER, { code, cost, vehicleType, paymentType, customerId, deliveryAddressId }, { signal: abortSignal })
    },
    getOngoingOrders(fromDate: string, toDate: string, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IOngoingOrdersItems>>(`${API_ROUTES.ORDERS.GET_ONGOING_ORDERS}?offset=0&count=4000&FromDate=${encodeURIComponent(fromDate)}&ToDate=${encodeURIComponent(toDate)}`, { signal: abortSignal });
    },
    getOrderHistories({ offset, count, toDate }: { offset: number, count: number, toDate: string }, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IOrderHistoriesPayload>>(`${API_ROUTES.ORDERS.GET_ORDER_HISTORIES}?offset=${offset}&count=${count}&fromDate=&toDate=${encodeURIComponent(toDate)}`, { signal: abortSignal });
    },
    cancelOrder(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<ICancelOrderPayload>>(API_ROUTES.ORDERS.CANCEL_ORDER(id), { signal: abortSignal });
    },
    getAllAreaCities({ offset, count }: { offset: number, count: number }, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IAreaCityListPayload>>(`${API_ROUTES.ORDERS.GET_AREAS_CITIES}?offset=${offset}&count=${count}`, { signal: abortSignal });
    }
}