import { ChangeEvent, KeyboardEvent } from "react";

class FormHelper {
    limitInputLength(e: ChangeEvent<HTMLInputElement>, limit: number) {
        if (e.target.value.length > limit) {
            e.target.value = e.target.value.slice(0, limit);
        }

        return this;
    }

    preventSpace(e: ChangeEvent<HTMLInputElement>) {
        e.target.value = e.target.value.replace(/\s/g, '');

        if (e.target.value.indexOf(" ") > -1) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1);
        }

        return this;
    }

    preventSpecialCharacters(e: ChangeEvent<HTMLInputElement>) {
        const reg = /^[a-zA-Z0-9]+$/;
        if (!reg.test(e.currentTarget.value)) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1);
        }

        return this;
    }

    enterJustNumber(e: ChangeEvent<HTMLInputElement>) {
        const reg = /^(\d*(?:\.\d+)?)[\.\d]*$/;

        if (!reg.test(e.currentTarget.value) || (e.currentTarget.value.match(/\./g) || []).length > 1) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1);
        }

        return this;
    }
}

export default FormHelper;