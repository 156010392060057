import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import "./styles.css";

interface ICountDownTimerButtonProps {
    handleTimerFinished: () => void;
}

const CountDownTimerButton: React.FC<ICountDownTimerButtonProps> = ({ handleTimerFinished }) => {
    const [timeLeft, setTimeLeft] = useState<number>(15);
    const [boxClass, setBoxClass] = useState<string>("box");

    useEffect(() => {
        setBoxClass("box start");
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(0);
            handleTimerFinished();
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [timeLeft]);

    return <Button variant="contained" color="info" disabled className={boxClass}>
        <span className="box-inner" style={{ color: "#888" }}>{timeLeft > 9 ? `00:${timeLeft}` : `00:0${timeLeft}`}</span>
    </Button>;
}

export default CountDownTimerButton;