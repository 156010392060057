import { Box } from "@mui/system";

interface IPageContentWrapperProps {
    children: JSX.Element;
}

const PageContentWrapper:React.FC<IPageContentWrapperProps> = ({children}) => {
    return <Box sx={{
        marginTop: "12px",
        backgroundColor: "#fff",
        padding: "12px",
        borderRadius: "8px",
        border: "1px solid #E6E6E6"
    }}>
        {children}
    </Box>
}

export default PageContentWrapper;