import { Box } from "@mui/material";
import React from "react";
import { FaHistory, FaListOl, FaTimes } from "react-icons/fa";
import { RxExit } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import Logo from "../../../../components/app/Logo";
import ROUTE_CONSTANTS from "../../../../routes/route-constant";

interface IDrawerProps {
    isSmallScreen: boolean;
    openSidebar: boolean;
    setOpenSidebar: (isOpen: boolean) => void;
    handleSigout: () => void;
}
export const Drawer: React.FC<IDrawerProps> = ({ isSmallScreen, openSidebar, setOpenSidebar, handleSigout }) => {
    const handleCloseSidebarAfterClick = () => {
        return isSmallScreen && setOpenSidebar(false);
    }

    return <aside className={openSidebar ? "sidebar" : "sidebar close"}>
        {isSmallScreen && <FaTimes className="close-siderbar" size={22} onClick={() => setOpenSidebar(false)} />}
        <Box sx={{ textAlign: "center" }}><Logo width={140} height={140} /></Box>
        <Box className="sidebar-item">
            <NavLink to={ROUTE_CONSTANTS.DASHBOARD.OrderList.ABSOLUTE}
                className={({ isActive }) => (isActive ? 'active' : 'inactive')} onClick={handleCloseSidebarAfterClick}>
                <FaListOl className="icon" size={21} />
                Paging List
            </NavLink>
        </Box>
        <Box className="sidebar-item">
            <NavLink to={ROUTE_CONSTANTS.DASHBOARD.OrderHistory.ABSOLUTE}
                className={({ isActive }) => (isActive ? 'active' : 'inactive')} onClick={handleCloseSidebarAfterClick}>
                <FaHistory className="icon" size={21} />
                Paging History
            </NavLink>
        </Box>
        {
            !isSmallScreen && <Box className="signout-wrapper">
                <span onClick={handleSigout}><RxExit size={22} style={{ transform: "rotateY(180deg)", verticalAlign: "middle" }} /> Logout</span>
            </Box>
        }
    </aside>;
}