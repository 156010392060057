import { Box, TableCell, TableRow } from "@mui/material";
import { IOngoingOrders, IOrderStatusEnum, OrderPaymentType, TOrderStatus } from "../../../../../api/types/orders";
import dateHelper from "../../../../../utils/date-helper";
import CustomTooltip from "../../../../../components/app/CustomTooltip";
import AcceptedRiderButton from "../AcceptedRiderButton";
import { CiDollar, CiWallet } from "react-icons/ci";
import { USER_ROLE } from "../../../../../types/user";
import { useGlobal } from "../../../../../context/GlobalContext";

interface IOrderHistoryItemProps {
    setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
    orderItem: IOngoingOrders;
    setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OrderHistoryItem: React.FC<IOrderHistoryItemProps> = ({ orderItem, setIsOpenAcceptedRiderModal, setSelectedOrder }) => {
    const { user } = useGlobal();
    const getStateFromOrderHistory = (colStatusType: TOrderStatus, completeFormat: boolean = false): {
        toastForm: string;
        valueForm: string
    } => {
        let item = orderItem.histories.filter(orderHistory => orderHistory.status === colStatusType);

        if (item.length > 0) {
            return completeFormat ? {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm")
            } : {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "HH:mm")
            }
        } else {
            return {
                toastForm: "",
                valueForm: "-"
            }
        }
    }

    const renderActionButttons = () => {
        let disableAcceptedRiderBtn: boolean = false;

        if ([IOrderStatusEnum.Pending, IOrderStatusEnum.ZoneCanceled].includes(orderItem.status)) {
            disableAcceptedRiderBtn = true;
        }

        return <>
            <AcceptedRiderButton
                isDisabled={disableAcceptedRiderBtn}
                handleOnClick={() => {
                    setIsOpenAcceptedRiderModal(true);
                    setSelectedOrder(orderItem);
                }}
            />
        </>;
    }

    const handleOrderItemClassName = () => {
        return orderItem.status === IOrderStatusEnum.ZoneCanceled ? "order_item_row canceled" : "order_item_row";
    }

    const handleOrderValue = () => {
        const icon = orderItem.paymentType === OrderPaymentType.Paid
            ? <CiWallet size={21} />
            : <CiDollar size={23} />;

        return <CustomTooltip title={orderItem.paymentType}>
            <Box display="flex" alignItems="center">{icon}&nbsp;{orderItem.cost}</Box>
        </CustomTooltip>
    }

    return <TableRow key={orderItem.id} className={handleOrderItemClassName()}>
        <TableCell sx={{ maxWidth: "130px", wordWrap: "break-word" }}>#{orderItem.code}</TableCell>
        <TableCell>{handleOrderValue()}</TableCell>
        { user?.role === USER_ROLE.GroupAgent && <TableCell>{orderItem.applicant}</TableCell>}
        <CustomTooltip title={getStateFromOrderHistory(IOrderStatusEnum.Pending, true).toastForm}>
            <TableCell>{getStateFromOrderHistory(IOrderStatusEnum.Pending, true).valueForm}</TableCell>
        </CustomTooltip>
        <CustomTooltip title={getStateFromOrderHistory(IOrderStatusEnum.RiderAccepted, true).toastForm}>
            <TableCell>{getStateFromOrderHistory(IOrderStatusEnum.RiderAccepted, true).valueForm}</TableCell>
        </CustomTooltip>
        <CustomTooltip title={getStateFromOrderHistory(IOrderStatusEnum.PickedUp, true).toastForm}>
            <TableCell>{getStateFromOrderHistory(IOrderStatusEnum.PickedUp, true).valueForm}</TableCell>
        </CustomTooltip>
        <CustomTooltip title={getStateFromOrderHistory(IOrderStatusEnum.Delivered, true).toastForm}>
            <TableCell>{getStateFromOrderHistory(IOrderStatusEnum.Delivered, true).valueForm}</TableCell>
        </CustomTooltip>
        <TableCell>{orderItem.vehicleType}</TableCell>
        <TableCell>{renderActionButttons()}</TableCell>
    </TableRow>
}

export default OrderHistoryItem;