import { Box } from "@mui/material";
import React from "react";
import NotFoundResultIllustration from "../../../assets/img/notFoundResult.svg";
import "./styles.css";

interface INotFoundResultProps {
    iconMaxWidth?: number;
}

export const NotFoundResult: React.FC<INotFoundResultProps> = ({ iconMaxWidth = 180 }) => {
    return <Box className="notFoundResult">
        <img src={NotFoundResultIllustration} alt="Not Found result" style={{ maxWidth: `${iconMaxWidth}px` }} />
    </Box>
}