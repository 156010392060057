import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CustomModal from "../../../../../components/app/CustomModal";
import { IOngoingOrders } from "../../../../../api/types/orders";
import { ordersService } from "../../../../../api/services/orders";
import SnackbarUtils from '../../../../../utils/set-snackbar';
import { useTranslation } from "react-i18next";
import "./styles.css";
import { LoadingButton } from "@mui/lab";


interface ICancelOrderConfirmationModalProps {
    orderItem: IOngoingOrders;
    isOpen: boolean;
    handleClose: () => void;
    handleConfirmCancel: (updatedOrder: IOngoingOrders) => void;
}

const CancelOrderConfirmationModal: React.FC<ICancelOrderConfirmationModalProps> = (props) => {
    const { orderItem, isOpen, handleClose, handleConfirmCancel } = props;
    const { t } = useTranslation();

    const [cancelOrderAbortController] = useState<AbortController>(new AbortController());
    const [loading, setLoading] = useState<boolean>(false);

    const cancelOrder = async () => {
        try {
            setLoading(true);
            const result = await ordersService.cancelOrder(orderItem.id, cancelOrderAbortController.signal);
            SnackbarUtils.success(t("messages.orderSuccessfullyCanceled"));
            // console.log(`cancel result API: ${result.data.data}`);
            let updatedOrder: IOngoingOrders = { ...orderItem, availableActions: result.data.data.availableActions };
            handleConfirmCancel(updatedOrder);
            handleClose();
        } catch (error) {
            console.log(error);
            handleClose();
        } finally {
            setLoading(false);
        }
    }

    return <CustomModal isOpen={isOpen} styles={{ maxWidth: "400px" }}>
        <Box className="cancel_order_Confirmation_container" p={1}>
            <Box textAlign="center" fontSize="1rem">
                <Typography textAlign="center" mb={1}>Are you sure that you want to cancel order?</Typography>
                <Typography fontSize="0.9em" color="#aaa">Order ID: &nbsp;#{orderItem.code}</Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={2} mt={3}>
                <Button variant="outlined" size="large" color="secondary"
                    onClick={handleClose}
                    disabled={loading}>No</Button>
                <LoadingButton variant="contained" size="large" color="info"
                    onClick={cancelOrder}
                    loading={loading}>Yes</LoadingButton>
            </Stack>
        </Box>
    </CustomModal>;
}

export default CancelOrderConfirmationModal;