import React, { Dispatch, ReactNode, useReducer } from "react";
import { contextFactory } from "../../../../utils/context-factory";
import { OrdersDataStoreAction } from "../OrdersDataStore/OrdersDataStore-Actions";
import { IOrdersDataStoreInitialState, ordersDataStoreInitialState, ordersDataStoreReducer } from "../OrdersDataStore/OrdersDataStore-Reducer";

export const [orderDataStateContext, useOrderDataState] = contextFactory<IOrdersDataStoreInitialState>("orderDataStateContext", "useOrderDataState");
export const [orderDataDispatchContext, useOrderDataDispatch] = contextFactory<Dispatch<OrdersDataStoreAction<any>>>("orderDataDispatchContext", "useOrderDataDispatch");

const OrderDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [orderDataStates, dispatch] = useReducer(ordersDataStoreReducer, ordersDataStoreInitialState);

    return <orderDataStateContext.Provider value={orderDataStates}>
        <orderDataDispatchContext.Provider value={dispatch}>
            {children}
        </orderDataDispatchContext.Provider>
    </orderDataStateContext.Provider>
}

export default OrderDataProvider;