export const SCREEN_BREAKPOINTS = {
    SM: {
        MIN: "320px",
        MAX: "480px"
    },
    MD: {
        MIN: "481px",
        MAX: "768px"
    },
    LG1: {
        MIN: "769px",
        MAX: "1024px"
    },
    LG2: {
        MIN: "1025px",
        MAX: "1200px"
    },
    XLG: {
        MIN: "1201px"
    }

}