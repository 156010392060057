import ReactDOM from 'react-dom/client';
import App from './App';
import CustomSnackbarProvider from './components/app/CustomSnackbarProvider';
import "./i18n";
import * as Sentry from "@sentry/react";
import './index.css';

if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}

if (window.REACT_APP_API_URL) {
  Sentry.init({
    dsn: "https://c20635ebb1de4b205818c5b16c11d4d5@o4504111123988480.ingest.sentry.io/4505856003801088",
    integrations: [new Sentry.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CustomSnackbarProvider>
    <App />
  </CustomSnackbarProvider>
);