import React from "react"
import OrderDataProvider from "../OrdersDataContext";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrderHistorytList from "../components/OrderHistorytList";
import { useTitle } from "../../../../hooks/useTitle";

const OrderHistory: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitle.pagingOrders"));

    return <Box>
        <OrderDataProvider>
            <OrderHistorytList />
        </OrderDataProvider>
    </Box>;
}

export default OrderHistory;