import * as Yup from "yup";
import { VehicleTypes } from "../api/types/vehicle";

const { string, object, mixed } = Yup;

export const orderRequestValidationSchema = (t: any) => object().shape({
    code: string().min(3).required(),
    cost: string().nullable(),
    vehicleType: mixed().oneOf([VehicleTypes.Bike, VehicleTypes.Car]).required(),
    phoneNumber: string()
        .min(6, "Phone number must be at least 6 characters.")
        .matches(/^[+]?[0-9]{6,13}$/, "Please enter a valid Phone Number.")
        .required()
});