import {
    BrowserRouter as Router,
    Routes as RoutesWrapper,
    Route,
    Navigate,
} from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import GeneralLayout from "../layouts/GeneralLayout";
import Login from "../pages/auth/Login";
import DashboardHome from "../pages/dashboard/Home";
import PageNotFound from "../pages/notFound";
import ROUTE_CONSTANTS from "./route-constant";
import OrderList from "../pages/dashboard/Orders/OnGoing";
import OrderHistory from "../pages/dashboard/Orders/History";
import { GlobalProvider } from "../context/GlobalContext";

const Routes: React.FC = () => {
    return <Router basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
        <GlobalProvider>
            <RoutesWrapper>
                <Route path={ROUTE_CONSTANTS.ROOT.RELATIVE} element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />} />
                <Route path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE} element={<GeneralLayout />} >
                    <Route path="" element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />} />
                    <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
                </Route>
                <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} element={<DashboardLayout />} >
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.HOME.ABSOLUTE} element={<DashboardHome />} />
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.OrderList.ABSOLUTE} element={<OrderList />} />
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.OrderHistory.ABSOLUTE} element={<OrderHistory />} />
                </Route>
                <Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<PageNotFound />} />
            </RoutesWrapper>
        </GlobalProvider>
    </Router >
};

export default Routes;