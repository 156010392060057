import { IconButton, Tooltip } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";

interface ICancelOrderButtonProps {
    isDisabled: boolean;
    handleOnClick: () => void;
}

const CancelOrderButton:React.FC<ICancelOrderButtonProps> = ({isDisabled, handleOnClick}) => {
    return <>
        <Tooltip title="Cancel Order">
            <span>
                <IconButton aria-label="cancel order" size="small" 
                        disabled={isDisabled}
                        onClick={handleOnClick}>
                    <IoIosCloseCircleOutline/>
                </IconButton>
            </span>
        </Tooltip>
    </>;
}

export default CancelOrderButton;