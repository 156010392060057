import dayjs from "dayjs";

const dateHelper = {
    formatDate(d: string,format:string = "ddd YYYY-MM-DD HH:mm") {
        if (!d) {
            return "---"
        }
        return dayjs(d).format(format);
    },
    getToday(format: string = 'YYYY-MM-DD') {
        return dayjs().format(format);
    },
    getStartEndByDate(date: string) {
        const rawDate = dayjs(date).format("YYYY-MM-DD");
        const start = `${rawDate}T00:00:00`;
        const end = `${rawDate}T23:59:59.9999999`;
        return {
            start: dayjs(start).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: dayjs(end).format("YYYY-MM-DDTHH:mm:ssZ")
        }
    },
    getStartEndByDates(s:string,e:string){
        const rawStartDate = dayjs(s).format("YYYY-MM-DD");
        const rawEndDate = dayjs(e).format("YYYY-MM-DD");
        const start = `${rawStartDate}T00:00:00`;
        const end = `${rawEndDate}T23:59:59.9999999`;
        return {
            start: dayjs(start).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: dayjs(end).format("YYYY-MM-DDTHH:mm:ssZ")
        }
    },
    getStartEndByDatesAndTimes(s:string,e:string){
        return {
            start: dayjs(s).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: dayjs(e).format("YYYY-MM-DDTHH:mm:ssZ")
        }
    },
    getStartEndFromThisMonth(hasTime = false , endTime = "23:59"){
        const year = dayjs().year();
        const month = dayjs().month() + 1;
        let result = {
            start:`${year}-${month<10?"0"+month:month}-01`,
            end:this.getToday()
        };

        if(hasTime) {
            return {
                start:result.start + "T00:00",
                end:result.end + "T" + endTime,
            }
        } else {
            return result;
        }
    },
    getDuration(seconds: number,humanize:boolean = true) {
        let h = Math.floor(seconds / 3600);
        let m = Math.floor(seconds % 3600 / 60);
        let s = Math.floor(seconds % 3600 % 60);

        if (!humanize) {
            return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
        }

        let [hDisplay, mDisplay, sDisplay] = ["", "", ""];

        if (h > 0) {
            hDisplay = h + (h === 1 ? " hour" : " hours");
        }

        if (m > 0) {
            mDisplay = m + (m === 1 ? " minute" : " minutes");
        }

        if (s > 0) {
            sDisplay = s + (s === 1 ? " second" : " seconds");
        }

        let displayFormatArray = [hDisplay, mDisplay, sDisplay];
        return displayFormatArray.filter(di => di).join(", ");
    },
    compareDateWithToday(start: string, unit: "hours" | "days" | "minutes" | "seconds") {
        const startDate = dayjs(start)
        const endDate = dayjs()
        return endDate.diff(startDate, unit);
    },
    getYesterdayDate() {
        const today = new Date();
        const yesterday = new Date(today);
    
        yesterday.setDate(yesterday.getDate() - 1);
    
        return yesterday.toLocaleDateString();
    },
    getShortFormatDuration(duration:string) {
        return duration.replace(/\b(?:minute|minutes)\b/gi,'m').replace(/\b(?:second|seconds)\b/gi,'s').replace(/\b(?:hour|hours)\b/gi,'h');
    }
    
}

export default dateHelper;